import Text from './components/Text';
import './App.css';

function App() {
  return (
    <div className="App">
      <Text />
      <Text />
      <Text />
      <Text />
      <Text />
      <Text />
      <Text />
      <Text />
      <Text />
      <Text />
      <Text />
    </div>
  );
}

export default App;
