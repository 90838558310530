import React, {Component} from "react";

class Text extends Component {
    state = {
        text: 'Привет, каждый компонент децентрализован'
    }
    
    onChangeText = (e) => {
        this.setState({text: e.target.value})
    }

    render() {
      return (
          <div>
              <p>{this.state.text}</p>
              <input type="text" onChange={this.onChangeText}  value={this.state.text}/>              
          </div>
      )
    }
  }

export default Text;
